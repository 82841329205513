import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../client.mjs';
import { ApiResponse, AffiliateType } from '../types';
import { dataOrError } from '../utils/dataOrError';

const getAffiliateTypes = async () => {
  const { data } = await service.get<ApiResponse<AffiliateType[]>>(
    `/Affiliate/ListAffiliateTypes`
  );
  return dataOrError<AffiliateType[]>(data);
};

export function useGetAffiliateTypes() {
  return useQuery({
    queryKey: ['affiliateTypes'],
    queryFn: () => getAffiliateTypes(),
  });
}
