import * as Sentry from '@sentry/gatsby';

export const sentryQabConfig = {
  dsn: 'https://50c572f2eb1a4957b18ce2acf2d59403@o207017.ingest.us.sentry.io/4505544315174912',
  integrations: [Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend(event) {
    console.log('Sending event to Sentry:', event);
    // Check if the error message contains the specified patterns
    if (
      event.exception &&
      event.exception.values &&
      event.exception.values.length > 0
    ) {
      const errorMessages = event.exception.values.map((value) => value.value);

      // Define patterns to match against error messages
      const patternsToFilter = [
        /Object Not Found Matching Id/i, // Case-insensitive check
        /ChunkLoadError/i, // Case-insensitive check
        /undefined is not an object \(evaluating 'r\["@context"\].toLowerCase\(\)'\)/i, // Case-insensitive check
        /We couldn't load "\/page-data\/\w+\/\d+\.json"/i, // Match any JSON filename
      ];

      // Check if any of the patterns match the error message
      for (const pattern of patternsToFilter) {
        if (errorMessages.some((message) => pattern.test(message))) {
          return null; // Return null to ignore the error
        }
      }
    }

    // Return the event for other types of errors
    return event;
  },
};
