// UK Proxy end points
module.exports = {
  productionApi:
    process.env.GATSBY_API_ENDPOINT_PROD ??
    'https://proxy-api.agriapet.co.uk/paws',
  uatApi:
    process.env.GATSBY_API_ENDPOINT_UAT ??
    'https://proxy-api-staging.agriapet.co.uk/paws',
  apiKey:
    'mKlno4TnhlxlMogC94eW54f2vyaVGhV7UycvufueWucmqD6l3MnvxC=rs=sLUYdM5X7pXbA1h91Vbajlq=427e/JVib8Uq98cu1fB8fTODdyoOeeZIZLZ17U0NIMVrhjUOr5DAdj03tA-jA8IJIb7UN=m0SG0L=l0GD6Rhv?y4zO1OJp2z3q!re3ANFh9l2=TvyS/grBO-9rDsrOkiepLonOSW-VSu-KPeFVwOoZWD3aI-UWhza!cFN2rbp6Linv',
  legacyKey: '8AC3A94C-4B3E-4201-A6C3-919813A621B5',
};
