/* eslint-disable react/no-danger */
import type { GatsbySSR } from 'gatsby';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GatsbyBrowser } from 'gatsby';
import { BaseLayout } from './src/components/BaseLayout';
import { PageProvider } from './src/context/pageContext';
import { BrochurewareDataLayerWrapper } from './src/components/BrochurewareDataLayerWrapper';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const pageID = props?.data?.page?.remoteId;
  const brand = props?.data?.page?.brand;
  const stickyCallToAction = props?.data?.page?.stickyCallToAction;
  const overrideStickyCTAButton = props?.data?.page?.overrideStickyCTAButton;

  return (
    <BrochurewareDataLayerWrapper
      data={props.data}
      location={props.location}
      pageContext={props.pageContext}
    >
      <PageProvider
        pageID={pageID}
        stickyCallToAction={stickyCallToAction}
        overrideStickyCTAButton={overrideStickyCTAButton}
      >
        <BaseLayout brand={brand} location={props.location}>
          {element}
        </BaseLayout>
      </PageProvider>
    </BrochurewareDataLayerWrapper>
  );
};
