/* eslint-disable react/no-danger */
import type { GatsbySSR, GatsbyBrowser } from 'gatsby';
import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GlobalLoaderProvider } from './src/context/GlobalLoaderContext';
import { MyAccountProvider } from './src/context/MyAccountContext';
import { msalConfig } from './src/authConfig.mjs';

// Create the MSAL instance outside the component tree to prevent it from being re-instantiated on re-renders.
const msalInstance = new PublicClientApplication(msalConfig);

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] &
  GatsbySSR['wrapRootElement'] = ({ element }) => (
  <MsalProvider instance={msalInstance}>
    <MyAccountProvider>
      <GlobalLoaderProvider>{element}</GlobalLoaderProvider>
    </MyAccountProvider>
  </MsalProvider>
);

export const onRenderBody: GatsbySSR['onRenderBody'] = ({
  setHeadComponents,
}) => {
  setHeadComponents([
    <script
      key="partytown-vanilla-config"
      dangerouslySetInnerHTML={{
        __html: `partytown = { debug: false }`,
      }}
    />,
  ]);
};
